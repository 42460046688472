import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import * as styles from '../styles/success.module.css'

const Success = () => {
  return (
    <div className={styles.successContainer}>
      <StaticImage
        className={styles.successLogo}
        alt="neutral"
        src="../images/logo-neutral-white.svg"
      />
      <h1 className={styles.successTitle}>お問い合わせありがとうございました！</h1>
      <p className={styles.successText}>
        24時間以内にメールにてご連絡させていただきます。
      </p>
      <p className={styles.successText}>
        <strong>迷惑メールフォルダ</strong>へ振り分けられてしまう<br/>
        ことがありますので、ご注意ください。
      </p>
      <Link to="/" className={styles.successHomeLink}>ホームへ戻る</Link>
    </div>
  )
}

export default Success
